<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/06加入我们.png"/>
        <div class="mobile-head-explain" style="margin-top:-30rem">
            <div class="mobile-head-explain-title">加入我们</div>
            <div class="mobile-head-explain-content">携手打造智慧世界</div>
        </div>
        <div class="mobile-title" style="margin-top:3.7rem;font-size:2.5rem;line-height: 3rem;">常招职位</div>
        <div class="mobile-content" style="font-size:2.8rem">
            比孚员工拥抱新技术，抓住机遇，推动行业进步。
            欢迎斗志昂扬、才华横溢、渴望创新的员工加入比孚。
        </div>
        <div class="mobile-content-box1">
            <div class="joinus-box">
                <div class="card">
                    <div class="title1">SAP销售</div>
                    <div class="title2">工作地点: 上海</div>
                </div>
                <div class="card">
                    <div class="title1">数据销售</div>
                    <div class="title2">工作地点: 上海</div>
                </div>
                <div class="card">
                    <div class="title1">客户经理/客户总监</div>
                    <div class="title2">工作地点: 上海</div>
                </div>
                <div class="card">
                    <div class="title1">产品经理</div>
                    <div class="title2">工作地点: 无锡</div>
                </div>
                <div class="card">
                    <div class="title1">SAP项目经理</div>
                    <div class="title2">工作地点: 上海</div>
                </div>
                <div class="card">
                    <div class="title1">Java项目经理</div>
                    <div class="title2">工作地点: 上海</div>
                </div>
                <div class="card">
                    <div class="title1">产品经理（供应链）</div>
                    <div class="title2">工作地点: 上海</div>
                </div>
                <div class="card">
                    <div class="title1">销售（食品饮料行业）</div>
                    <div class="title2">工作地点: 无锡</div>
                </div>
            </div>
        </div>
        <div class="mobile-content" style="font-size:2.8rem;margin-top: 3rem;margin-bottom: 5rem;">
            如果您对以上岗位有兴趣，请联系公司HR：<br/>
            <div style="color:#11A84F;font-size:2.8rem;margin-top: 1rem;">HR@bizfocus.cn</div>
        </div>
        
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            serviceIndex:1,
            showDescription:true,

        };
    },

    mounted() {
        
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.mobile-content-box1{
    width: 66.5rem;
    margin: auto;
    margin-top: 1rem;
}
.joinus-box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // margin-bottom: 5rem;
    .card{
        width: 32rem;
        height: 12rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 1rem 0px #E7E7E7;
        border-top: 0.2rem solid #11A84F;

        margin-top: 2rem;
        transition: all .5s;
        .title1{
            height: 3.6rem;
            font-size: 2.4rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #333333;
            line-height: 3.6rem;
            margin-top: 2.7rem;
            text-align: center;
        }
        .title2{
            height: 1.9rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #999999;
            line-height: 2.2rem;
            margin-top: 0.4rem;
            text-align: center;
        }
    }
}
.email-title{
    height: 3.6rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #333333;
    line-height: 3.6rem;
    display: flex;
    margin: 4.5rem 0 0 75.3rem;
}
.email-title-1{
    height: 4.6rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #11A84F;
    line-height: 3rem;
    }
</style>